import { Injectable, Input, HostListener } from '@angular/core';
declare let printJS: any;

@Injectable()
export class PrintService {
   constructor() {
  }

  PrintHtml(id) {
    printJS(id, 'html');
  }

}
