import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';

let tableSectionOffset = 0;
@Directive({
  selector: '[appBodyevents]'
})
export class BodyeventsDirective {
  tableoffset: number;
  constructor(private el: ElementRef) { }

  @HostListener('window:scroll') onScroll() {
    const headerTable = this.el.nativeElement.querySelector('.staticOnScroll');
    const lowerTable = this.el.nativeElement.querySelector('.rowtable');
    const scroll =
      window.scrollY // Modern Way (Chrome, Firefox)
      || window.pageYOffset // (Modern IE, including IE11
      || document.documentElement.scrollTop; // (Old IE, 6,7,8)
    if (headerTable && lowerTable) {
      if (scroll >= (tableSectionOffset + headerTable.offsetHeight)) {
        headerTable.classList.add('fixedtableheader');
        lowerTable.classList.add('extra-margin-top');
        // lowerTable.style.marginTop = headerTable.offsetHeight+'px';
      } else {
        headerTable.classList.remove('fixedtableheader');
        lowerTable.classList.remove('extra-margin-top');
        // lowerTable.style.marginTop = '0px';
      }
    }
  }



  setTableOffset() {
    const headerTable = this.el.nativeElement;
    if (headerTable) {
      // this.tableoffset=headerTable.offsetTop;
      tableSectionOffset = headerTable.offsetTop;
    }
  }

}
