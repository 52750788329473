import * as AppString from './component_string';
import { pathList } from './constants';

export const COMMON_OBJECT = (() => {
    const CASE1 = AppString.COMMON_STRING.COMPANY1 + '_' + AppString.COMMON_STRING.INTERNAL_USER;
    const CASE2 = AppString.COMMON_STRING.COMPANY1 + '_' + AppString.COMMON_STRING.EXTERNAL_USER;
    const CASE3 = AppString.COMMON_STRING.COMPANY2 + '_' + AppString.COMMON_STRING.INTERNAL_USER;
    const CASE4 = AppString.COMMON_STRING.COMPANY2 + '_' + AppString.COMMON_STRING.EXTERNAL_USER;
    const currencyObject = {
        INR: '₹',
        USD: '$',
        AUD: '$',
        EUR: '€',
        GBP: '£',
        CAD: '$'
    };
    const FOOTER_DEFAULT_ITEM_FIRST_OPTIONS = {
        text: 'Export Grid Options',
        value: null
    };

    return {
        CASE1,
        CASE2,
        CASE3,
        CASE4,
        currencyObject,
        FOOTER_DEFAULT_ITEM_FIRST_OPTIONS
    };
})();

export const ANONYMOUS_COMPONENT = (() => {
    const monthList: Array<{ monthName: string; monthId: string }> = [
        { monthName: 'January', monthId: '01' },
        { monthName: 'February', monthId: '02' },
        { monthName: 'March', monthId: '03' },
        { monthName: 'April', monthId: '04' },
        { monthName: 'May', monthId: '05' },
        { monthName: 'June', monthId: '06' },
        { monthName: 'July', monthId: '07' },
        { monthName: 'August', monthId: '08' },
        { monthName: 'September', monthId: '09' },
        { monthName: 'October', monthId: '10' },
        { monthName: 'November', monthId: '11' },
        { monthName: 'December', monthId: '12' }
      ];

      const yearList: Array<string> = ['2018', '2019', '2020', '2021'];

      return {
        monthList,
        yearList
      };
})();
