<div class="row" id="errorPage-div">
  <div id="errorPage-details" class="col s6 offset-s3 no-padding mainContainer">
    <div id="errorPage-msgContainer" class="msgContainer center-align">
      Access Denied. Please contact your system administrator.
    </div>
    <!-- <div id="errorPage-button-div" class="button-marg">
          <button id="errorPage-button" class="k-button k-primary" (click)="goToPortal()">Go TO CSC Portal</button>
      </div> -->
  </div>
</div>
