import { Injectable } from '@angular/core';
import { ApiConfigService } from './apiConfig.service';
import { ApiHttpService } from './apiHttp.service';
import { LocalstorageService } from '../utilService/localstorage.service';
import { CustomerRelatedService } from '../utilService/customerRelated.service';

import { LoaderstatusService } from './../../components/mainloader/loaderstatus.service';
import * as AllModels from '../../models/model.pathconfig';
import { MsgManager } from '../msgManager/msgManager.service';
import { StudInvoiceData } from '../../stubdata/invoiceStubData';
import { environment } from '../../../environments/environment';
import { ExportToPdfService } from '../utilService/exportToPdf.service';
import { ChildWindowService } from '../utilService/childWindow.service';

@Injectable()
export class ApiMainService {
    pdfcontent;
    private withCredentials: boolean = environment.withCredentials ? environment.withCredentials : false;

    constructor(
        private apiConfigService: ApiConfigService,
        private apiHttpService: ApiHttpService,
        private localStorageService: LocalstorageService,
        private loaderstatusService: LoaderstatusService,
        private customerService: CustomerRelatedService,
        private msgManager: MsgManager,
        private exportToPdfService: ExportToPdfService,
        private childWindowService: ChildWindowService) { }

        authenticateUser(paylaodObj): Promise<any> {
            this.showLoader();
            paylaodObj = this.envalopePaylaod(paylaodObj, 0);
            const promise = new Promise((resolve, reject) => {
                this.apiHttpService
                    .REQUEST(this.apiConfigService.apiEndPointObj['autenticateUser'], paylaodObj)
                    .then(response => {
                        this.hideLoader();
                        this.errorMessageHandler(response);
                        if (response.status === 400) {

                            this.msgManager.errorMsg('Login failed');
                        } else if (response.data && response.data.accessToken && response.data.accessTokenName) {

                            const accessToken = response.data.accessToken;
                            const accessTokenName = response.data.accessTokenName;
                            this.localStorageService.setChacheData('ACCESS_TOKEN', accessToken);
                            this.localStorageService.setChacheData('ACCESS_TOKEN_NAME', accessTokenName);
                            resolve('success login');
                        } else {

                            this.msgManager.errorMsg('response format incorrect');
                            reject('response format incorrect');

                        }
                    }, error => {
                        this.hideLoader();
                        reject();
                    });
            });
            return promise;
        }

    lookUpSearch(paylaodObj): Promise<any> {
        paylaodObj = this.envalopePaylaod(paylaodObj, 0);
        this.showLoader();
        const promise = new Promise((resolve, reject) => {
            this.apiHttpService.
                REQUEST(this.apiConfigService.apiEndPointObj['lookUpSearch'], paylaodObj)
                .then(response => {
                    this.hideLoader();
                    if (response) {
                        if (response.data && response.data.OutputParameter &&
                            response.data.OutputParameter.SearchResult) {

                            resolve(response.data.OutputParameter.SearchResult);
                        } else {

                            resolve([]);
                        }
                    } else {
                        reject();
                    }
                }, error => {
                    this.hideLoader();
                    reject();
                });
        });
        return promise;
    }

    customerSearch(paylaodObj, isContextSearch: boolean): Promise<any> {
        this.showLoader();
        const promise = new Promise((resolve, reject) => {
            this.apiHttpService.
                REQUEST(this.apiConfigService.apiEndPointObj['customerSearch'], null)
                .then(response => {
                    this.hideLoader();
                    if (response
                        && response.data
                        && response.data.CustomerHeaderInfo
                        && response.data.CustomerHeaderInfo.CustomerHeaderInfoDetails) {
                        const customerInfo = response.data.CustomerHeaderInfo.CustomerHeaderInfoDetails;
                        if (isContextSearch) {
                            let noDataCustInfoObj = AllModels.CollectorUserInfo;
                            noDataCustInfoObj = customerInfo[0];
                            for (const prop in noDataCustInfoObj) {
                                if (noDataCustInfoObj[prop]) {
                                    if (!noDataCustInfoObj[prop]) {
                                        noDataCustInfoObj[prop] = 'NA';
                                    }
                                    this.localStorageService.setChacheData('CUSTOMER_CONTEXT_INFO', noDataCustInfoObj);
                                }
                            }
                            this.localStorageService.setChacheData('CUSTOMER_CONTEXT_INFO', customerInfo[0]);
                            resolve(customerInfo[0]);
                        } else {
                            const tempcustomerInfo = customerInfo[0];
                            tempcustomerInfo['businessName'] = tempcustomerInfo['businessName'] ? tempcustomerInfo['businessName'] : 'CSC';
                            this.localStorageService.setChacheData('CUSTOMER_INFO', tempcustomerInfo);
                            resolve(customerInfo[0]);
                        }
                    } else {
                        reject();
                    }
                }, error => {
                    this.hideLoader();
                    reject();
                });
        });
        return promise;
    }

    anonymousPay(paylaodObj): Promise<any> {
        paylaodObj = this.envalopePaylaod(paylaodObj, 0);
        this.showLoader();
        const promise = new Promise((resolve, reject) => {
            this.apiHttpService.
                REQUEST(this.apiConfigService.apiEndPointObj['anonymousPay'], paylaodObj)
                .then(response => {
                    this.hideLoader();
                    this.errorMessageHandler(response);
                    if (response && response.data) {
                        resolve(response);
                    } else {
                        resolve([]);
                    }
                }, error => {
                    this.hideLoader();
                    reject();
                });
        });
        return promise;
    }
    customerContext(payloadObj): Promise<any> {
        this.showLoader();
        payloadObj = this.envalopePaylaod(payloadObj, 0);
        const promise = new Promise((resolve, reject) => {
            this.apiHttpService.REQUEST(this.apiConfigService.apiEndPointObj['customerContext'], payloadObj)
                .then(response => {
                    this.hideLoader();
                    this.errorMessageHandler(response);
                    if (response.data && response.data.statusCode === 'S' && response.data.statusMessage) {
                        resolve(response);
                    }
                },
                    error => {
                        this.hideLoader();
                        reject();
                    });
        });
        return promise;
    }

    sendReceiptEmail(payloadObj): Promise<any> {
        payloadObj = this.envalopePaylaod(payloadObj, 0);
        this.showLoader();
        const promise = new Promise((resolve, reject) => {
            this.apiHttpService.REQUEST(this.apiConfigService.apiEndPointObj['sendReceiptByEmail'], payloadObj)
                .then(response => {
                    this.hideLoader();
                    resolve(response);
                }, error => {
                    this.hideLoader();
                    reject(error);
                });
        });
        // this.hideLoader();
        return promise;
    }

    getSurchargeConfigData() {

        const promise = new Promise((resolve, reject) => {
          this.apiHttpService
            .REQUEST(this.apiConfigService.apiEndPointObj['getSurchargeConfigurations'])
            .then(
              success => {
                if (success.error) {
                  resolve([]);
                } else if (success) {
                  resolve(success);
                }
              },
              error => {
                reject(error);
              }
            );
        });
    
        return promise;
    
      }

      callBINRequest(payloadObj): Promise<any> {
        this.showLoader();
        const authObj = {
        };
        const promise = new Promise((resolve, reject) => {
          this.apiHttpService
            .REQUEST(
              this.apiConfigService.apiEndPointObj['BINRequest'],
              payloadObj,
              authObj
            )
            .then(
              response => {
                this.hideLoader();
                if (response) {
                  resolve(response);
                }
              },
              error => {
                this.hideLoader();
                reject(error);
              }
            );
        });
    
        return promise;
      }


    validateRecapche(payloadObj): Promise<any> {
        const promise = new Promise((resolve, reject) => {
            this.apiHttpService.REQUEST(this.apiConfigService.apiEndPointObj['recaptcha'], payloadObj)
                .then((response) => {
                    resolve(response);
                },
                    error => {
                    });
        });

        return promise;
    }

    ssoIrec(): Promise<any> {
        this.showLoader();
        const xyz = this.apiConfigService.apiEndPointObj['ssoIrec'];
        // xyz.url=xyz.url+'?bes';
        const promise = new Promise<void>((resolve, reject) => {
            this.apiHttpService
                .REQUEST(xyz)
                .then(response => {
                    this.hideLoader();
                    resolve();
                }, error => {
                    this.hideLoader();
                    reject();
                });
        });
        return promise;
    }

    callGeoApi(firstParam: string, secParam: string, thirdParam?: string) {
        const tmpUrl = this.apiConfigService.apiEndPointObj['geoApi'].url;
        const apiUrl = {};
        if (firstParam === 'countries') {
            apiUrl['url'] = tmpUrl + firstParam + '/search-by-name/' + secParam;
        } else {
            apiUrl['url'] = tmpUrl + firstParam + '/search-by-name/' + thirdParam + '/' + secParam;
        }

        apiUrl['method'] = this.apiConfigService.apiEndPointObj['geoApi'].method;
        const promise = new Promise((resolve, reject) => {
            this.apiHttpService.REQUEST(apiUrl).then(success => {
                if (success.error) {
                    resolve([]);
                } else if (success) {
                    resolve(success);
                }
            }, error => {
                reject(error);
            });
        });

        return promise;
    }

    singleProcessPayment(payloadObj, anonymousAccNo?) {
        this.showLoader();
        console.log('anonymousAccNo--->',anonymousAccNo);
        const accNo = anonymousAccNo ? anonymousAccNo : this.customerService.getCustomerInfo('accountNumber');
        const accNoObj = {
            customeraccountnumber: accNo
        };
        const promise = new Promise((resolve, reject) => {
            this.apiHttpService.REQUEST(this.apiConfigService.apiEndPointObj['singleProcessPayment'], payloadObj, accNoObj, 0, false)
                .then(response => {
                    this.hideLoader();
                    if (response) {
                        resolve(response);
                    }
                }, error => {
                    this.hideLoader();
                    reject(error);
                });
        });

        return promise;
    }

    singleEbsAuth(paylaodObj): Promise<any> {
        paylaodObj = this.envalopePaylaod(paylaodObj, 0);
        this.showLoader();
      console.log('singleEBS---------->');
        const promise = new Promise((resolve, reject) => {
            this.apiHttpService.
                REQUEST(this.apiConfigService.apiEndPointObj['ebsAuth'], paylaodObj)
                .then(response => {
                    console.log('success------>');
                    this.hideLoader();
                    this.errorMessageHandler(response);
                    if (response && response.data) {
                        resolve(response);
                    } else {
                        resolve([]);
                    }
                }, error => {
                    this.hideLoader();
                    reject();
                });
        });
        return promise;
    }

    getAllCountries() {
        const promise = new Promise((resolve, reject) => {
            this.apiHttpService.REQUEST(this.apiConfigService.apiEndPointObj['getAllCountries']).then(success => {
                if (success.error) {
                    resolve([]);
                } else if (success) {
                    resolve(success);
                }
            }, error => {
                reject(error);
            });
        });

        return promise;
    }

    getStatesForCountry(stateId) {
        const apiUrl = {};
        apiUrl['url'] = this.apiConfigService.apiEndPointObj['getAllStates'].url + stateId;
        apiUrl['method'] = this.apiConfigService.apiEndPointObj['getAllStates'].method;
        const promise = new Promise((resolve, reject) => {
            this.apiHttpService.REQUEST(apiUrl).then(success => {
                if (success.error) {
                    resolve([]);
                } else if (success) {
                    resolve(success);
                }
            }, error => {
                reject(error);
            });
        });

        return promise;
    }
    callAddressSuggesterAPI(payloadObj): Promise<any> {

        const promise = new Promise((resolve, reject) => {
          this.apiHttpService.REQUEST(
              this.apiConfigService.apiEndPointObj['addressSuggestions'],
              payloadObj /* , authObj */
            ).then(
              response => {
                if (response) {
                  resolve(response);
                } else {
                  resolve([]);
                }
              },
              error => {
                return([]);
              }
            );
        });
        return promise;
      }

    private showLoader() {
        this.loaderstatusService.loadingStatusSubject.next(true);
    }
    private hideLoader() {
        this.loaderstatusService.loadingStatusSubject.next(false);
    }
    private envalopePaylaod(payloadObj: object, type: number) {
        const bodyObj = { data: {} };
        if (type === 0) {
            bodyObj.data = payloadObj;
        }
        if (type === 1) {
            bodyObj.data = { params: payloadObj };
        }
        if (type === 2) {
            bodyObj.data = { params: { params: { param: payloadObj } } };
        }

        return bodyObj;
    }

    private errorMessageHandler(response) {
        if (response && response.data && response.data.statusCode === 'E' && response.data.statusMessage) {
            this.msgManager.errorMsg(response.data.statusMessage);
        }
        if (response && response.errorMessage) {
            this.msgManager.errorMsg(response.errorMessage);
        }
    }

}
