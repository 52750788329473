import { Injectable } from '@angular/core';
import { LocalstorageService } from '../utilService/localstorage.service';

@Injectable()
export class CustomerRelatedService {
    constructor(private localStorageService: LocalstorageService) { }

    getCustomerInfo(property?) {
        let customerInfo;
        const customerType = this.getCustomerType();
        if (customerType === 'INTERNAL') {
            customerInfo = this.localStorageService.getChacheData('CUSTOMER_CONTEXT_INFO');
        } else if (customerType === 'EXTERNAL') {
            customerInfo = this.localStorageService.getChacheData('CUSTOMER_INFO');
        }
        return property ? customerInfo[property] : customerInfo;
    }
    getCustomerType() {
        let customerInfo = this.localStorageService.getChacheData('CUSTOMER_INFO');
        if (!customerInfo) {
            customerInfo = this.localStorageService.getChacheData('CUSTOMER_CONTEXT_INFO');
        }
        return customerInfo.userType;
    }
    isContextSet() {
        const contextSet = this.localStorageService.getChacheData('IS_CONTEXT_SET');
        return contextSet;
    }
}
