import { Component, OnInit } from '@angular/core';
import { Event, NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';

declare const UniversalFooter: any;
declare let gtag: (config: string,environemnt: string,obj: object) => void;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private router: Router){}

  ngOnInit() {
    this.setUpAnalytics();
  }
  /*This function will setup google analytics for angular routes */
setUpAnalytics(): void {
  this.router.events.pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: any) => {
      gtag('config', environment.gaTrackingId,
      {
        page_location: event.urlAfterRedirects
      }
    );
    });
}

}
