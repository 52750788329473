export class CCAuth {
    merchid: string;
    account: string;
    expiry: string;
    amount: string;
    currency: string;
    name: string;
    address: string;
    city: string;
    region: string;
    country: string;
    postal: string;
    cvv2: string;
}

export class CreditCardPaymentToken {
    cardDescription: string;
    cardholderName: string;
    cardIssuer: string;
    expiryDate: string;
    expiryFlag: string;
    instrumentSecurityCode: string;
    singleUseFlag: string;
}

export class CreditCardAddressToken {
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    addressLine4: string;
    addressReference: string;
    city: string;
    countryCode: string;
    postalCode: string;
    stateProvince: string;
}

export class CreditCardToken {
    creditCardPaymentToken = new CreditCardPaymentToken();
    creditCardPaymentTokenAddress = new CreditCardAddressToken();


}

export class TransactionReceipt {
    currencyCode: string;
    paymentDetails: Array<any>;
    sourceApplication: string;
    emailAddress: string;
    surchargeAmount?: string;
}

export class ACHToken {
    accountHolderName: string;
    bankAccountType: string;
    expiryFlag: string;
    singleUseFlag: string;
}

export class ACHAuth {
    merchid: string;
    name: string;
    account: string;
    bankaba: string;
    userfields: any;
}


