<div *ngIf="showToaster" class="toaster-wrapper">

    <div class="toaster-content">
        <span *ngIf="!hideCloseToaster" class="c-i-cancel_1 text-xl cursor-pointer right cross-icon" (click)="closeToaster()"></span>
        <div class="toaster-body" [ngClass]="{'csc-success':(toasterObj.msgType==='SUCCESS'),
        'csc-error ':(toasterObj.msgType==='ERROR'),
        'csc-info ':(toasterObj.msgType==='INFO'),
       'csc-warning ':(toasterObj.msgType==='WARNING')}">
            <span class="csc-icon"></span>
            {{toasterObj.msg}}
        </div>
    </div>
</div>