import { Injectable } from '@angular/core';

@Injectable()
export class SessionCacheService {
  private cacheObj = {};
  private defaultDataSetTime = 2 * 60 * 60 * 1000; // 2 hours

  constructor() {}
  getChacheData(key: string): any {
    const data = this.cacheObj[key];
    if (!data) {
      return null;
    } else {
      const cachestorageModel = (JSON.parse(data)) as CachestorageModel;
      if (this.checkIfTimeIsValid(cachestorageModel.dataSetTime)) {
        return cachestorageModel.data;
      } else {
        return null;
      }
    }
  }
  setChacheData(key: string, data: any, time?: number): void {
    const cachestorageModel = new CachestorageModel();
    const currentTime: number = new Date().getTime();
    cachestorageModel.data = data;
    cachestorageModel.dataSetTime = time
      ? currentTime + time
      : currentTime + this.defaultDataSetTime;
    this.cacheObj[key] = JSON.stringify(cachestorageModel);
  }
  resetChacheData(key): void {
    delete this.cacheObj[key];
  }
  resetAllChacheData(): void {
    this.cacheObj = {};
  }
  private checkIfTimeIsValid(dataSetTime: number): boolean {
    const timeDifference = dataSetTime - new Date().getTime();
    if (timeDifference > 0) {
      return true;
    } else {
      return false;
    }
  }
}

class CachestorageModel {
  data: any;
  dataSetTime: number;
}
