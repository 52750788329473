
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuickPaymentComponent } from '../components/quick-payment/quick-payment.component';
import { CustomerInfoResolver } from './../resolvers/customer-info-resolver';
import { ErrorPageComponent } from '../components/error-page/error-page.component';

const routes: Routes = [
    {
        path: 'quikpay/:company',
        component: QuickPaymentComponent,
        data: { stateName: 'QuickPayment' },
    },
    {
        path: 'error',
        component: ErrorPageComponent,
    },
    {
        path: 'anonymousPayment',
        loadChildren: () => import('../components/anonymous-payment/anonymous-payment.module').then(m=> m.AnonymousPaymentModule),
        data: { stateName: 'Payment' }
    },
    { path: '', redirectTo: '/quikpay/CSC', pathMatch: 'full' },
    { path: '**', redirectTo: '/error' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
    providers: [CustomerInfoResolver]
})
export class AppRoutingModule { }
