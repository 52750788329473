// let baseUrl;

const baseUrl = '/cscirec/irecinterface/anonymous'; /* For Ajesh war file */

export const environment = {
  production: false,
  envName: 'uat',
  loggingSentryEnabled: false,
  withCredentials: true,
  local: false,
  baseUrl,
  siteKey: '6LeuPlkUAAAAAMcsXUNJdYLXazWDN9PDURbi5QG6',
  // eslint-disable-next-line max-len
  redirectUrl: 'https://inp-uat.cscglobal.com/cscirec/irecinterface',
  cscPortalUrl: 'https://myuat.cscglobal.com',
  cdnBaseUrl: 'https://ocu.cscglobal.com',
  ccIframe: 'https://csc-uat.cardconnect.com/itoke/ajax-tokenizer.html',
  serviceDesk: 'Please contact 1-800-927-9801 extn 66842 or invoiceinquiry@cscglobal.com for assistance.',
  backgroundImageUrl: 'https://myuat.cscglobal.com/cscportal/login/en_US/images/cscglobal_burstbg2.jpg',
  cscLogoUrl: 'https://ocu.cscglobal.com/cdn/gateway/csc/csc-logo-dbs.png',
  gaTrackingId: 'G-79W0JWWRM6'
};
