
const imagePathUrl = 'assets/images';

export const pathList = {
    imageUrl: imagePathUrl,
    // MAIN_LOADER_IMG:imagePathUrl+'/mainLoader.gif',
    MAIN_LOADER_IMG: imagePathUrl + '/csc_spinner.gif',
    CSCLOGOURL: imagePathUrl + '/csclogo.png',
    INCORPORATELOGOURL: imagePathUrl + '/incorporate.png',
    DOWN_ICON: imagePathUrl + '/iconDown.png',
    HAMBURGER_ICON: imagePathUrl + '/hamburger.png',
    CHEQUE_ICON: imagePathUrl + '/iconcheck.png',
    CARD_ICON: imagePathUrl + '/iconccard.png'

};
