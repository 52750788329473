<img width="15%" [src]="cscLogoUrl">
<div class="row anonymous-container" [ngStyle]="getUrl()">
    <div *ngIf="IExplorerAgent" class="textMessage"><i class="c-icon c-warning" aria-hidden="true"></i>We noticed you’re currently using an outdated browser. 
        To ensure you have the best possible experience, please switch to a 
        <a href="https://login.cscglobal.com/cscglobal-login/public/browserpolicy"
         target="_blank">supported browser</a> before continuing. 
        Our online portal may support other web browsers or browser versions older than those mentioned, however, our software development teams do not actively test or certify those browser versions.
        </div>
    <div class="login-credential col xl4 offset-xl4 l5 offset-l3 m6 offset-m3 s12 offset-s0">
        <div class="row">
            <span class="col s12 center text-xl">
                <h1>CSC Online Invoice Payment</h1>
            </span>
            <h3 class="col s12 center">To make a payment, please enter your account number and invoice number</h3>
        </div>
        <div class="mt20">
            <div class="row">
                <span class="col s12 no-padding">Account number
                    <!-- <span class="csc-secondary-color-coral">*</span> -->
                </span>
                <input type="text" class="k-textbox col s12" placeholder="Enter Account#" [(ngModel)]="accountNumber">
            </div>
            <div class="row">
                <span class="col s12 no-padding">Invoice number
                    <!-- <span class="csc-secondary-color-coral">*</span> -->
                </span>
                <input type="text" class="k-textbox col s12" placeholder="Enter Invoice#" [(ngModel)]="invoiceNumber">
            </div>
            <div class="row">
                <div id="cscRecaptcha" #recaptcha class="g-recaptcha"></div>
            </div>
            <button class="k-button k-primary col s12"  (click)="singleEbsAuth()"  [disabled]="!accountNumber || !invoiceNumber || !recapthasuccessfull">PROCEED</button>
                <span class="col s12 pt5">Please <a [href]="redirectUrl" (click)= 'sendGAEvent()'>login</a> to better manage all of your invoices</span>

        </div>
    </div>
</div>
<div class="row targetUniversalFooter mb0">
    <div class="col s12 no-padding" id="targetUniversalFooter">
    </div>
</div>