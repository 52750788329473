import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayfilter',
  pure: false
})
export class ArrayfilterPipe implements PipeTransform {

  transform(array: Array<any>, filterObj: [any]): Array<any> {
    const arr = array.filter(arrObj => {
      let match = true;
        for (const prop in filterObj) {
          if (filterObj[prop]) {
            if (arrObj[prop]) {
            match = match && (arrObj[prop].toString()).startsWith(filterObj[prop]);
            } else {
              match = false;
            }
          }
        }

      return match;
    });
    return arr;
  }

}
