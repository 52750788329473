import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LocalstorageService } from '../utilService/localstorage.service';
import { SessionCacheService } from './sessioncache.service';
import { Title } from '@angular/platform-browser';

@Injectable()
export class NavigationService {
  public name: string;
  constructor(
    private router: Router,
    private localStorage: LocalstorageService,
    private route: ActivatedRoute,
    private sessionCacheService: SessionCacheService,
    private titleService: Title
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Traverse the active route tree
        let snapshot = route.snapshot;
        let activated = route.firstChild;
        if (activated != null) {
          while (activated != null) {
            snapshot = activated.snapshot;
            activated = activated.firstChild;
          }
        }

        // Try finding the 'stateName' from the data
        this.name = snapshot.data['stateName'] || '';
        /* let screenName = '';
        switch (true) {
          case snapshot.data['stateName'] === 'QuickPayment':
            screenName = 'QuickPay';
            break;
          case snapshot.data['stateName'] === 'Payment':
            screenName = 'Payment';
            break;

        } */
        this.titleService.setTitle('CSC Invoices & Payments - CSC Quikpay');
        // google analytics
        if (window['gtag']) {
          (window as any).gtag('set', 'page', event.urlAfterRedirects);
          (window as any).gtag('send', 'pageview');
        }

      }
    });
  }
  /* gotoLoginPage() {
    this.router.navigate(['home']);
  }
  gotoLogoutPage() {
    this.clearLocalStoragBeforeLogout();
    this.router.navigate(['logout']);
  } */

  gotoErrorPage() {
    this.router.navigate(['error']);
  }
  checkCurrentRoute(name: string): boolean {
    return this.name === name;
  }
  getCurrent(): string {
    return this.name;
  }
  goToBaseUrl() {
    this.router.navigate(['quickPayment/CSC']);
  }

  private clearLocalStoragBeforeLogout() {
    this.localStorage.resetAllChacheData();
    this.sessionCacheService.resetAllChacheData();
  }
}
