import { Injectable } from '@angular/core';

@Injectable()
export class ExportToPdfService {
    pdfcontent;

    constructor() {
    }

    downloadBlobPdf(response, fileName) {
        const mediaType = 'application/pdf';
        const blob = new Blob([response._body], { type: mediaType });
        // IE 10+ (native saveAs)
        if (typeof navigator !== 'undefined' && (navigator as any).msSaveOrOpenBlob) {
            (navigator as any).msSaveOrOpenBlob(blob, fileName);
        } else {
            const urlObj = window.URL.createObjectURL(blob);
            this.pdfcontent = urlObj;
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.href = urlObj;
            window.open(a.href, '_blank');
        }
    }

    downloadBase64Pdf(base64Byte, fileName, newWindow?) {
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) { // IE workaround
            const byteCharacters = atob(base64Byte);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
        } else { // much easier if not IE
            const w = newWindow ? newWindow : window.open('about:blank');
            setTimeout (() => { // FireFox seems to require a setTimeout for this to work.
                const iframe = w.document.createElement('iframe');
                iframe.style.width = '100%';
                iframe.style.height = '100%';
                iframe.style.overflow = 'hidden';
                const base64content = 'data:application/pdf;base64,' + base64Byte;
                w.document.body.appendChild(iframe).src = base64content;
            }, 0);

        }
    }

}
