import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arraysort',
  pure: false
})
export class ArraysortPipe implements PipeTransform {

  transform(array: Array<any>, args: string, order: number): Array<any> {
    // order 1 is asecending and -1 is decending
    if ( args ) {
    array.sort((a: any, b: any) => {
    if (a[args] < b[args]) {
    return -1 * order;
    } else if (a[args] > b[args]) {
    return 1 * order;
    } else {
    return 0;
    }
    });
    }
    return array;
    }

}
