
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import * as Service from '../services/service.pathconfig';
import { MsgManager } from '../services/msgManager/msgManager.service';


@Injectable()
export class CustomerInfoResolver implements Resolve<any> {

    constructor(private apiMainService: Service.ApiMainService,
        private localStorageService: Service.LocalstorageService,
        private msgManager: MsgManager,
        private router: Router) { }
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const promise = new Promise((resolve, reject) => {
            const customerInfo = this.localStorageService.getChacheData('CUSTOMER_INFO');
            if (customerInfo) {
                resolve(customerInfo);
            } else {
                this.apiMainService.customerSearch({}, false)
                    .then(response => {
                        resolve(response);
                    }, error => {
                        this.router.navigate(['logout']);
                        this.msgManager.errorMsg(104);
                        // reject(error);
                    });
            }
        });
        return promise;
    }
}
