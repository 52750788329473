import { Observable ,  BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';


@Injectable()

export class SendDataToComponent {
    jsonDefaultObj = {};

    subscribe(key: string, callback: () => void) {
        if (!this.jsonDefaultObj[key]) {
            this.jsonDefaultObj[key] = new BehaviorSubject(null);
            this.jsonDefaultObj[key].subscribe(callback);
        }
    }
    publish(key: string, json: any) {
        if (this.jsonDefaultObj[key] && (json !== null || json !== undefined)) {
            this.jsonDefaultObj[key].next(json);
        }
    }
    unsubscribe(key: string) {
        if (this.jsonDefaultObj[key]) {
            this.jsonDefaultObj[key].unsubscribe();
            delete this.jsonDefaultObj[key];
        }
    }

}
