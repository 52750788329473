import { Injectable } from '@angular/core';
import { LocalstorageService } from '../utilService/localstorage.service';
import { SessionCacheService } from '../utilService/sessioncache.service';
import { ApiMainService } from '../apiService/apiMain.service';
import { CustomerRelatedService } from '../utilService/customerRelated.service';
import { NavigationService } from '../utilService/navigation.service';
import { SendDataToComponent } from '../utilService/sendDataToComponent';
import { LoaderstatusService } from '../../components/mainloader/loaderstatus.service';

@Injectable()
export class BackgroundApiCallService {

    constructor(
        private localStorageService: LocalstorageService,
        private apiMainService: ApiMainService,
        private customerRelatedService: CustomerRelatedService,
        private navService: NavigationService,
        private sendDataToComponent: SendDataToComponent,
        private sessionCacheService: SessionCacheService,
        private loaderStatusService: LoaderstatusService
    ) { }

    callApi() {
        this.dashboardApiCall();
    }

    private dashboardApiCall() {
        if (!this.navService.checkCurrentRoute('Dashboard')) {
            const payloadObj = {
                customerNumber: ''
            };
            payloadObj.customerNumber = this.customerRelatedService.getCustomerInfo('accountNumber');
            this.sessionCacheService.setChacheData('DASHBOARD_LOADER', true);
        }
    }
}
