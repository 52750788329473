import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusDateRange',
  pure: true
})
export class StatusDateRangePipe implements PipeTransform {

  transform(array: Array<any>,
    dateRangeObject: object,
    filterObj: object,
    statusPropertyName: string,
    datePropertyName: string
  ) {
    const selectedOptionArray = [];
    let ALLsected = false;
    for (const prop in filterObj) {
      if (prop === 'ALL' && filterObj[prop]) {
        ALLsected = true;
      } else if (filterObj[prop]) {
        selectedOptionArray.push(prop);
      }
    }
    let arr = array;
    if (!ALLsected) {
      arr = array.filter(arrObj => {
        let match = false;
        if (selectedOptionArray.indexOf(arrObj[statusPropertyName]) > -1) {
          match = true;
        }
        return match;
      });
    }
    const startDate = (dateRangeObject['filterRowStartDate'] && dateRangeObject['filterRowStartDate']['jsdate']) ?
      dateRangeObject['filterRowStartDate']['jsdate'] : null;
    const endDate = (dateRangeObject['filterRowEndDate'] && dateRangeObject['filterRowEndDate']['jsdate']) ?
      dateRangeObject['filterRowEndDate']['jsdate'] : null;
    if (endDate) {
      endDate.setDate(endDate.getDate() + 1);
    }
    if (startDate || endDate) {
      arr = arr.filter(arrObj => {
        let match = false;
        const propDate = new Date(arrObj[datePropertyName]);
        if ((startDate && endDate && (propDate >= startDate) && (propDate <= endDate))
          || (startDate && !endDate && (propDate >= startDate))
          || (endDate && !startDate && (propDate <= endDate))
        ) {
          match = true;
        }
        return match;
      });
    }

    return arr;


  }

}
