import { Injectable } from '@angular/core';

@Injectable()
export class LocalstorageService {
  private cacheObj = localStorage;
  // private defaultDataSetTime = 2 * 60 * 60 * 1000; // 2 hours
  private defaultDataSetTime = 24 * 60 * 60 * 1000; // 24 hours
  private uniquekey: string;

  constructor() {
    this.uniquekey = this.getUniqueKey();
    this.detectAndDeleteOldChache();
  }
  getChacheData(key: string): any {
    const data = this.cacheObj.getItem(this.modifykey(key));
    if (!data) {
      return null;
    } else {
      let cachestorageModel;
      try {
        cachestorageModel = (JSON.parse(data) as CachestorageModel);
        if (cachestorageModel) {
          if (this.checkIfTimeIsValid(cachestorageModel.dataSetTime)) {
            return cachestorageModel.data;
          } else {
            this.resetChacheData(key);
            return null;
          }
        }
      } catch (e) {
        console.log(e);
        return null;
      }
    }
  }
  getChacheDataOnce(key: string): any {
    const data = this.cacheObj.getItem(this.modifykey(key));
    if (!data) {
      return null;
    } else {
      let cachestorageModel;
      try {
        cachestorageModel = (JSON.parse(data) as CachestorageModel);
        if (cachestorageModel) {
          if (this.checkIfTimeIsValid(cachestorageModel.dataSetTime)) {
            const storeddata = cachestorageModel.data;
            this.resetChacheData(key);
            return storeddata;
          } else {
            this.resetChacheData(key);
            return null;
          }
        }
      } catch (e) {
        console.log(e);
        return null;
      }
    }
  }
  setChacheData(key: string, data: any, time?: number): void {
    const cachestorageModel = new CachestorageModel();
    const currentTime: number = new Date().getTime();
    cachestorageModel.data = data;
    cachestorageModel.dataSetTime = time
      ? currentTime + time
      : currentTime + this.defaultDataSetTime;
    this.cacheObj.setItem(
      this.modifykey(key),
      JSON.stringify(cachestorageModel)
    );
  }
  resetChacheData(key): void {
    this.cacheObj.removeItem(this.modifykey(key));
  }
  resetAllChacheData(): void {
    this.cacheObj.clear();
  }
  getUniqueKey() {
    return Math.random()
      .toString(36)
      .substr(2);
  }
  modifykey(key) {
    if (this.uniquekey) {
      key += this.uniquekey;
    }
    return key;
  }

  public deleteCurrentCache() {
    const allkeys = Object.getOwnPropertyNames(this.cacheObj);
    allkeys.forEach(key => {
      if (key.indexOf(this.uniquekey) > -1) {
        this.cacheObj.removeItem(key);
      }
    });
  }

  private checkIfTimeIsValid(dataSetTime: number): boolean {
    const timeDifference = dataSetTime - new Date().getTime();
    if (timeDifference > 0) {
      return true;
    } else {
      return false;
    }
  }

  private detectAndDeleteOldChache() {
    const allkeys = Object.getOwnPropertyNames(this.cacheObj);
    allkeys.forEach(key => {
      const data = this.cacheObj.getItem(key);
      if (data) {
        let cachestorageModel;
        try {
          cachestorageModel = (JSON.parse(data) as CachestorageModel);
          if (cachestorageModel.data) {
            const defaultoldTime = 2 * 24 * 60 * 60 * 1000; // 2 days
            const currentDate = new Date().getTime();
            const timeDifference = currentDate - cachestorageModel.dataSetTime;
            if (timeDifference > defaultoldTime) {
              this.cacheObj.removeItem(key);
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    });
  }
}

class CachestorageModel {
  data: any;
  dataSetTime: number;
}
