import { Component, OnInit } from '@angular/core';
import { LoaderstatusService } from './loaderstatus.service';
import { pathList } from './../../constants/constants';

@Component({
  selector: 'app-mainloader',
  templateUrl: './mainloader.component.html',
  styleUrls: ['./mainloader.component.scss']
})
export class MainloaderComponent implements OnInit {
  isLoaderShowing: boolean;
  loadingCount: number;
  loaderImagePath = pathList.MAIN_LOADER_IMG;
  constructor(private loadingStaus: LoaderstatusService) {
    this.isLoaderShowing = false;
    this.loadingCount = 0;
    this.loadingStaus.loadingStatusSubject.subscribe((isLoaderShowing) => {
      if (this.loadingCount > 0 || isLoaderShowing) {
        this.loadingCount = isLoaderShowing ? ++this.loadingCount : --this.loadingCount;
      }
      this.isLoaderShowing = !this.loadingCount ? false : true;
    });

  }

  ngOnInit() {
  }
}
