import { Injectable } from '@angular/core';

@Injectable()
export class ScreenManagerService {
    constructor() { }

    public scrollToTop() {
        window.scroll(0, 0);
    }
}
