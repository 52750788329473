import { Injectable } from '@angular/core';
import { ExportToExcelService } from '../utilService/exportToExcel.service';
import { LocalstorageService } from '../utilService/localstorage.service';
import { SessionCacheService } from '../utilService/sessioncache.service';
import { FormatDateService } from '../utilService/formatDate.service';
import { pathList } from '../../constants/constants';
import * as AppStrings from '../../constants/component_string';
import * as AppObjects from '../../constants/component_object';
import * as AllModels from '../../models/model.pathconfig';

@Injectable()
export class DataformatterService {
  componentvariables = Object.assign(
    AppStrings.COMMON_STRING,
    AppObjects.COMMON_OBJECT,
    pathList
  );

  constructor(
    private exportServiceObj: ExportToExcelService,
    private localStorageService: LocalstorageService,
    private sessionCacheService: SessionCacheService,
    private formatDateService: FormatDateService
  ) { }

  arrayToCSV(inputarr: any[], withOutQuotes?: boolean): string {
    const temparr = inputarr.map(inputvalue => {
      if (typeof inputvalue === 'object') {
        return inputvalue.receiptNumber; /* for multiple receipts-object is made */
      }
      if (withOutQuotes) {
        return inputvalue;
      } else {
        return '\'' + inputvalue + '\'';
      }
    });
    const tempstring = temparr.join();
    return tempstring;
  }

  multiSelectToArray(selectionArray: any[], selectedOptions: any[]): any[] {
    const selectedOptionValueArr = [];
    for (const selected of selectedOptions) {
      const selectedValue = selected.value;
      selectedOptionValueArr.push(selectedValue);
    }
    return selectedOptionValueArr;
  }
  lookupResponseFormatter(response: any[], InputPropArr: string[]) {
    const finaloutput = [];
    response.forEach((SearchResultDetailsObj: any[]) => {
      const innerObj = {};
      let SearchResultDetails = [];
      if (SearchResultDetailsObj['SearchResultDetails']) {
        SearchResultDetails = SearchResultDetailsObj['SearchResultDetails'];
      }
      if (SearchResultDetails) {
        SearchResultDetails.forEach(searchObj => {
          if (InputPropArr.indexOf(searchObj.name) >= 0) {
            innerObj[searchObj.name] = searchObj.value;
          }
        });
        finaloutput.push(innerObj);
      }
    });
    return finaloutput;
  }
}
