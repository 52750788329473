import { Component, OnInit, NgZone, OnDestroy, AfterViewChecked, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import * as Service from '../../services/service.pathconfig';
import * as AppStrings from '../../constants/component_string';
import * as AppObjects from '../../constants/component_object';
import { environment } from '../../../environments/environment';
import { GOOGLE_ANALYTICS_ACTION, GOOGLE_ANALYTICS_CATEGORY, GOOGLE_ANALYTICS_LABEL } from '../../constants/google-analytics-events';
declare const gtag;
declare let grecaptcha;
declare const UniversalFooter: any;
@Component({
  selector: 'app-quick-payment',
  templateUrl: './quick-payment.component.html',
  styleUrls: ['./quick-payment.component.scss']
})
export class QuickPaymentComponent implements OnInit, OnDestroy {
  @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;
  componentVariables = Object.assign(
    AppStrings.COMMON_STRING,
    AppObjects.COMMON_OBJECT
  );

  company: string;
  accountNumber: string;
  invoiceNumber: string;
  quickPaymentObj: object;
  showErrorMessage = false;
  recapthasuccessfull = false;
  contextObj;
  authtoken;
  siteKey;
  redirectUrl;
  // orIcon=pathList.OR_LINE;
  currentFullYear = (new Date()).getFullYear();
  cscLogoUrl = environment.cscLogoUrl;
  cscBackgroundUrl = environment.backgroundImageUrl;
  IExplorerAgent: boolean;
  private subscription: Subscription;


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private localStorageService: Service.LocalstorageService,
    private apiMainService: Service.ApiMainService,
    private sessionCacheService: Service.SessionCacheService,
    private zone: NgZone) {
    window['QuickPaymentComponent'] = {
      zone: this.zone,
      componentFn: (value) => this.reCapthaCallbackInComponent(value),
      component: this,
    };
    this.siteKey = environment.siteKey;
    this.redirectUrl = environment.redirectUrl;

    const container = document.getElementsByClassName('iRec-container');
    if (container && container.length > 0 && screen && screen.availHeight) {
      container[0]['style']['min-height'] = screen.availHeight - 200 + 'px';
    }
  }

  ngOnInit() {
    // Get the user-agent string
    const userAgentString = window.navigator.userAgent.toLowerCase();
    // Detect Internet Explorer
    this.IExplorerAgent = userAgentString.indexOf('trident') > -1;

    this.loadUniversalFooter();
    this.addRecaptchaScript();
  }

  addRecaptchaScript() {
    window['grecaptchaCallback'] = () => {
      this.renderRecapctha();
    };

    (function(d, s, id, obj){
      let js;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        obj.renderRecapctha(); return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&render=explicit';
      js.async = true;
      js.defer = true;
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));
  }

  loadUniversalFooter() {
    const uf_simple = {
      footerType: 'simple',
      cscPortalUrl: environment.cscPortalUrl,
      cdnBaseUrl: environment.cdnBaseUrl,
      targetElementId: 'targetUniversalFooter',
      nav: [
        { url: environment.cscPortalUrl + '/global/web/csc/home', text: 'Home' },
        { url: environment.cscPortalUrl + '/global/web/csc/contact-us.html', text: 'Contact Us' },
        { url: environment.cscPortalUrl + '/global/web/csc/privacy.html', text: 'Privacy and Cookies' },
        { url: environment.cscPortalUrl + '/global/web/csc/legal.html', text: 'Legal' },
        { url: environment.cscPortalUrl + '/cscportal/browser-support.html', text: 'Browser Policy' }
      ]
    };
    const ufi = new UniversalFooter(uf_simple);
  }

  renderRecapctha() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement,
      {
        sitekey:  environment.siteKey, theme: 'light', callback: 'reCapthaCallback', 'expired-callback'() {
          this.recapthasuccessfull = false;
        }
      });
  }

  goTologin() {
    this.router.navigate(['/login']);
  }

  ngOnDestroy() {

  }
  goToAnonymous() {
    this.invoiceNumber = this.invoiceNumber.trim();
    this.accountNumber = this.accountNumber.trim();

    this.quickPaymentObj = {
      invoiceNumber: this.invoiceNumber,
      accountNumber: this.accountNumber
    };
    this.apiMainService.anonymousPay(this.quickPaymentObj)
      .then(response => {
        if (response.data && response.data.statusCode === 'S') {
          this.sessionCacheService.setChacheData('ANONYMOUSPAYMENTDATA', response.data);
          this.contextObj = {
            customerId: this.accountNumber,
            invoiceNumber: ''
          };
          this.apiMainService.customerContext(this.contextObj);
          this.router.navigate(['anonymousPayment']);
        } else {
          this.showErrorMessage = true;
        }
      }, error => {
        this.showErrorMessage = true;
      });
  }
  reCapthaCallbackInComponent(response) {
    gtag('event',  GOOGLE_ANALYTICS_ACTION.Verification, {
      event_category: GOOGLE_ANALYTICS_CATEGORY.QuickPayments,
      event_label: GOOGLE_ANALYTICS_LABEL.qp1});
    this.authtoken = response;
    this.recapthasuccessfull = true;
  }

  singleEbsAuth() {
    this.invoiceNumber = this.invoiceNumber.trim();
    this.accountNumber = this.accountNumber.trim();
    gtag('event', GOOGLE_ANALYTICS_ACTION.Pay,{
      event_category: GOOGLE_ANALYTICS_CATEGORY.QuickPayments,
      event_label: GOOGLE_ANALYTICS_LABEL.qp2});
    const payloadObj = {
      accountNumber: this.accountNumber,
      invoiceNumber: this.invoiceNumber,
      authToken: this.authtoken
    };

    this.apiMainService.singleEbsAuth(payloadObj).then(success => {
      if (success.data && success.data.statusCode === 'S') {
        this.sessionCacheService.setChacheData('ANONYMOUSPAYMENTDATA', success.data);
        this.contextObj = {
          customerId: this.accountNumber,
          invoiceNumber: ''
        };
        this.apiMainService.customerContext(this.contextObj);
        this.router.navigate(['anonymousPayment']);
      } else {
        this.showErrorMessage = true;
        this.recapthasuccessfull = false;
        grecaptcha.reset();
      }
    }, error => {
      this.recapthasuccessfull = false;
      grecaptcha.reset();
      this.showErrorMessage = true;
    });
  }
  sendGAEvent() {
    gtag('event', GOOGLE_ANALYTICS_ACTION.Pay, {
      event_category: GOOGLE_ANALYTICS_CATEGORY.QuickPayments,
      event_label: GOOGLE_ANALYTICS_LABEL.qp3});
  }

  getUrl() {
    const styles = {
      'background-image': 'url(' + this.cscBackgroundUrl + ')'
    };
    return styles;
  }
}
