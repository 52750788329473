import { OnInit, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class ApiConfigService {
  apiEndPointObj = {};
  private soaUrl: string;
  private pdfUrl: string;
  private baseUrl: string;
  private geoUrl: string;


  constructor() {
    this.baseUrl = environment.baseUrl;
    this.geoUrl = environment.baseUrl + '/geonamesapi/';
    this.pdfUrl = 'assets/pdf/Roboto_Specimen_Book.pdf';
    this.init();
  }
  private init() {
    this.apiEndPointObj['autenticateUser'] = {
      url: this.baseUrl + '/userTokenization/query',
      method: 'POST'
    };
    /* this.apiEndPointObj['invoiceSearch'] = {
      url: this.baseUrl + '/invoices/query',
      method: 'POST'
    }; */
    this.apiEndPointObj['lookUpSearch'] = {
      url: this.baseUrl + '/customers/query',
      method: 'POST'
    };
    this.apiEndPointObj['customerSearch'] = {
      url: this.baseUrl + '/customerHeaderInfo/query',
      method: 'GET'
    };
    this.apiEndPointObj['minit'] = {
      url: this.baseUrl + '/userContext/initialize',
      method: 'GET'
    };
    /* this.apiEndPointObj['paymentPDF'] = {
      url: this.pdfUrl,
      method: 'GET'
    }; */
    this.apiEndPointObj['customerContext'] = {
      url: this.baseUrl + '/customerContext/set',
      method: 'POST'
    };
    this.apiEndPointObj['anonymousPay'] = {
      url: this.baseUrl + '/invoiceInfoDetails/query',
      method: 'POST'
    };
    /* this.apiEndPointObj['paymentSearch'] = {
      url: this.baseUrl + '/payments/query',
      method: 'POST'
    };
    this.apiEndPointObj['clickWindowInvoiceId'] = {
      url: this.baseUrl + '/paymentsByInvoice/query',
      method: 'POST'
    };
    this.apiEndPointObj['ClickWindowReceiptId'] = {
      url: this.baseUrl + '/paymentsByReceipt/query',
      method: 'POST'
    };
    this.apiEndPointObj['invoiceDashboardDisplay'] = {
      url: this.baseUrl + '/invoiceDashboardDisplay/query',
      method: 'GET'
    }
    this.apiEndPointObj['matterWindow'] = {
      url: this.baseUrl + '/matterDetails/query',
      method: 'POST'
    };
    this.apiEndPointObj['paymentDashboard'] = {
      url: this.baseUrl + '/paymentDashboard/query',
      method: 'GET'
    };
    this.apiEndPointObj['sendMatterNumber'] = {
      url: this.baseUrl + '/matterNumber/update',
      method: 'POST'
    };
    this.apiEndPointObj['sendReceiptByEmail'] = {
      url: this.baseUrl + '/mailReceipt/send',
      method: 'POST'
    };
    this.apiEndPointObj['pdfDownload'] = {
      url: this.baseUrl + '/receipt/download',
      method: 'POST'
    };
    this.apiEndPointObj['invoiceLineDetails'] = {
      url: this.baseUrl + '/invoiceLineDetails/query',
      method: 'POST'
    };

    this.apiEndPointObj['paymentLineDetails'] = {
      url: this.baseUrl + '/paymentLineDetails/query',
      method: 'POST'
    };

    this.apiEndPointObj['adjustmentReason'] = {
      url: this.baseUrl + '/customers/query',
      method: 'POST'
    };
    this.apiEndPointObj['submitAdjustment'] = {
      url: this.baseUrl + '/invoiceAdjustment/query',
      method: 'POST'
    };
    this.apiEndPointObj['ssoIrec'] = {
      url: this.baseUrl + '/ssoIrec',
      method: 'GET'
    }; */

    /* ---- Payment Flow Apis --- */

    this.apiEndPointObj['saveTokenCreditCard'] = {
      url: this.baseUrl + '/creditCardPaymentTokens', /* 1 */
      method: 'POST'
    };
    this.apiEndPointObj['getACHDetails'] = {
      url: this.baseUrl + '/achPaymentTokens', /* 3 */
      method: 'GET'
    };

    this.apiEndPointObj['getCreditCardDetails'] = { /* 2 */
      url: this.baseUrl + '/creditCardPaymentTokens',
      method: 'GET'
    };
    this.apiEndPointObj['saveACHDetails'] = {
      url: this.baseUrl + '/achPaymentTokens', /* 4 */
      method: 'POST'
    };

    this.apiEndPointObj['ACHPaymentReceipts'] = {
      url: this.baseUrl + '/achPaymentReceipts', /* 5 */
      method: 'POST'
    };

    this.apiEndPointObj['CreditCardPaymentReceipts'] = {
      url: this.baseUrl + '/creditCardPaymentReceipts', /* 6 */
      method: 'POST'
    };
    this.apiEndPointObj['geoApi'] = {
      url: this.geoUrl,
      method: 'GET'
    };
    this.apiEndPointObj['ccAuth'] = {
      url: this.baseUrl + '/auth',
      method: 'POST'
    };
    this.apiEndPointObj['recaptcha'] = {
      url: this.baseUrl + '/reCaptcha',
      method: 'POST'
    };
    this.apiEndPointObj['singleProcessPayment'] = {
      url: this.baseUrl + '/processPayment',
      method: 'POST'
    };
    this.apiEndPointObj['ebsAuth'] = {
      url: this.baseUrl + '/ebsAuth',
      method: 'POST'
    };

    this.apiEndPointObj['getAllCountries'] = {
      url: this.geoUrl + 'countries',
      // url: 'http://geonames-api.ingress.dev.csc.local/geonames-api/countries?all=true',
      method: 'GET'
    };

    this.apiEndPointObj['getAllStates'] = {
      url: this.geoUrl + 'states/countries/',
      // url: 'http://geonames-api.ingress.dev.csc.local/geonames-api/states/countries/',
      method: 'GET'
    };
    this.apiEndPointObj['getSurchargeConfigurations'] = {
      url: this.baseUrl + '/config/surcharge',
      method: 'GET'
    };

    this.apiEndPointObj['BINRequest'] = {
      url: this.baseUrl + '/BINRequest',
      method: 'POST'
    };
    this.apiEndPointObj['addressSuggestions'] = {
      url: this.baseUrl + '/address/suggestions',
      method: 'POST'
    }

  }
}
