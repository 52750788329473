
import { Injectable } from '@angular/core';
import * as Raven from 'raven-js';

import { environment } from '../../../environments/environment';
import { MsgManager } from '../msgManager/msgManager.service';
import { LocalstorageService } from '../utilService/localstorage.service';
import { NavigationService } from '../utilService/navigation.service';
import { LoaderstatusService } from './../../components/mainloader/loaderstatus.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ApiHttpService {
    private withCredentials: boolean = environment.withCredentials ? environment.withCredentials : false;
    constructor(private http: HttpClient,
        private msgManager: MsgManager,
        private navigationService: NavigationService,
        private loaderstatusService: LoaderstatusService,
        private localstorageService: LocalstorageService) { }

    // private showLoader() {
    //   this.loaderstatusService.loadingStatusSubject.next(true);
    // }
    // private hideLoader() {
    //   this.loaderstatusService.loadingStatusSubject.next(false);
    // }

    callFinalApi(apiConfig,data,responseTypeNumber,withCredentialsForThisApi,finalhttpHeadersobj): Promise<any> {
        const responseTypes = responseTypeNumber?
     (responseTypeNumber === 0 ? 'json' : 'arraybuffer') : 'json';
      console.log('callFInalnewHttp--->');
        return this.http.request(
            apiConfig['method'],
            apiConfig['url'],
            {
              body: data? data : null,
              headers: finalhttpHeadersobj,
              observe: 'response',
              responseType: responseTypeNumber?
               (responseTypeNumber === 0 ? 'json' : 'arraybuffer') : 'json',
              withCredentials: withCredentialsForThisApi !== undefined
              ? withCredentialsForThisApi
              : this.withCredentials
            })
            .toPromise()
            .then(response => {
                // this.hideLoader();
                if (response && response.status === 401 ||
                    (!!response.headers &&
                        (response.headers.get('returnCode') === '401' ||
                            (response.headers.get('messages') &&
                                response.headers.get('messages').indexOf('JBO') >= 0) || // this needs testing
                            (!!response.headers.get('content-type') && response.headers.get('content-type').indexOf('text/html') >= 0)
                        )
                    )
                ) {
                    this.navigationService.goToBaseUrl();
                }
                if (response.headers && response.headers.get('irecapiid') && response.headers.get('irecfinp')) {
                    // save localstorage
                    const irecapiid = response.headers.get('irecapiid');
                    const irecfinp = response.headers.get('irecfinp');
                    this.localstorageService.setChacheData('irecapiid', irecapiid);
                    this.localstorageService.setChacheData('irecfinp', irecfinp);
                }
                if (response.headers && response.headers.get('haserrors') === 'true') {
                    let errorMsg = response.headers.get('messages');
                    // this.msgManager.errorMsg(errorMsg);
                    if (errorMsg) {
                        errorMsg = errorMsg.split('+').join(' ');
                        this.checkMessageText(errorMsg);
                    }
                    if (response.headers.get('operationId') === 'iReceivablesGetInvoiceInformation') {
                        return response;
                    }
                } else if (responseTypes === 'arraybuffer') {
                    return response;
                } else {
                    console.log('angular V15 Request-->');
                    return response.body;
                }
            })
            .catch(this.handleError(this));
    }

    REQUEST(
        apiConfig: object,
        data?: any,
        extraHeaderObj?: object,
        responseTypeNumber?: number,
        withCredentialsForThisApi?: boolean): Promise<any> {
        const responseTypeArr = ['Json', 'ArrayBuffer', 'Blob', 'Text'];
          console.log('REQUEST---->');
        // const headerobj = {
        //     'Content-Type': 'application/json',
        //     'Cache-Control': 'no-cache',
        //     Pragma: 'no-cache',
        //     Expires: 'Sat, 01 Jan 2000 00:00:00 GMT'
        // };
        const headerobj = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: 'Sat, 01 Jan 2000 00:00:00 GMT'
          };
        if (extraHeaderObj) {
            for (const prop in extraHeaderObj) {
                if (headerobj[prop]) {
                    headerobj[prop] = headerobj[prop] + ',' + extraHeaderObj[prop];
                } else {
                    headerobj[prop] = extraHeaderObj[prop];
                }

            }
        }

        // get headers from localstorage
        const irecapiid = this.localstorageService.getChacheData('irecapiid');
        const irecfinp = this.localstorageService.getChacheData('irecfinp');
        if (irecapiid && irecfinp) {
            headerobj['irecapiid'] = irecapiid;
            headerobj['irecfinp'] = irecfinp;
        }

        // const requestObj = new Request({
        //     method: apiConfig['method'],
        //     body: data ? data : null,
        //     url: apiConfig['url'],
        //     headers: new Headers(headerobj),
        //     withCredentials: withCredentialsForThisApi !== undefined ? withCredentialsForThisApi : this.withCredentials,
        //     responseType: ResponseContentType[responseTypeArr[reponseTypeNumber ? reponseTypeNumber : 0]],
        // });
        const finalhttpHeadersobj = new HttpHeaders(headerobj);
       // return this.callFinalApi(requestObj);
       return this.callFinalApi(
        apiConfig,data,responseTypeNumber,
        withCredentialsForThisApi,finalhttpHeadersobj);
    }

    checkMessageText(message) {
        if (message.indexOf('FND_SESSION_ICX_EXPIRED') >= 0 || message.indexOf('JBO') >= 0) {
            this.navigationService.goToBaseUrl();
        } else {
            this.msgManager.errorMsg(message);
        }
    }

    logSentryIOExceprtion(error) {
        if (environment.loggingSentryEnabled) {
            Raven.captureException(new Error(`Tomcat down ${error}`));
        }
    }

    private handleError(self) {
        // self.hideLoader();
        return error => {
            try {
                if (error.status === 401 ||
                    (!!error.headers &&
                        (error.headers.get('returnCode') === '401' ||
                            (error.headers.get('messages') &&
                                error.headers.get('messages').indexOf('JBO') >= 0) || // this needs testing
                            (!!error.headers.get('content-type') && error.headers.get('content-type').indexOf('text/html') >= 0)
                        )
                    )
                ) {
                    self.navigationService.goToPortalUrl();
                }

                // log error for 404 issue in sentry.io --start
                if (error.status === 404 || (!!error.headers && error.headers.get('returnCode') === '404')) {
                    self.logSentryIOExceprtion(error);
                }
                // log error for 404 issue in sentry.io --end

                if (error.headers && error.headers.get('hasErrors') === 'true') {
                    let errorMsg = error.headers.get('messages');
                    if (errorMsg) {
                        errorMsg = errorMsg.split('+').join(' ');
                        self.checkMessageText(errorMsg);
                    }
                } else {
                    self.msgManager.errorMsg(111);
                }

                return Promise.reject(error.message || error);
            } catch (error) {
                const ErrorMsg = 'Some Error Occured Try Again Later';
                self.msgManager.errorMsg(111);
                return Promise.reject(ErrorMsg);
            }
        };
    }

}
