export class BankAccountNgModel {
  nameOnAccount: string;
  accountNumber: string;
  accountNumberAgain: string;
  bankRoutingNumber: string;
  bankRoutingNumberAgain: string;
  accNumberToken: string;
  reference: string;
  accountType: string;
  emailId: string;
  emailSelected = true;
}

export class CreditCardNgModel {
  cardNumber: string;
  expiryDate: string;
  securityCode: string;
  cardHolderName: string;
  addressLine1: string;
  addressLine2: string;
  country: string;
  state: string;
  city: string;
  zipCode: string;
  emailId: string;
  emailSelected = true;
}
