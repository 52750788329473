import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ToasterService {
  toasterServiceSubject = new BehaviorSubject(null);
  constructor() {}

  show(msg: string, msgType: string, flag?, callback?: () => void) {
    const toasterObj = {
      msgType,
      msg,
      flag,
      callback
    };
    this.toasterServiceSubject.next(toasterObj);
  }
}
