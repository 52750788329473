import { BrowserModule } from '@angular/platform-browser';
import * as Raven from 'raven-js';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogModule } from '@progress/kendo-angular-dialog';

import { AppRoutingModule } from './modules/app-routing.module';
import { AppComponent } from './app.component';

import { ArrayfilterPipe } from './pipes/arrayfilter.pipe';
import { ArraysortPipe } from './pipes/arraysort.pipe';
import { ExtractStringPipe } from './pipes/extractString.pipe';
import { QuickPaymentComponent } from './components/quick-payment/quick-payment.component';
import { StatusDateRangePipe } from './pipes/status-date-range.pipe';
import { MainloaderComponent } from './components/mainloader/mainloader.component';
import { LoaderstatusService } from './components/mainloader/loaderstatus.service';
import { BodyeventsDirective } from './directives/bodyevents.directive';

import { ToasterComponent } from './components/toaster/toaster.component';
import { ToasterService } from './components/toaster/toaster.service';

import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import 'hammerjs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';


import { LayoutModule } from '@progress/kendo-angular-layout';
import { ErrorPageComponent } from './components/error-page/error-page.component';

import { environment } from '../environments/environment';
import { ApiConfigService } from './services/apiService/apiConfig.service';
import { ApiHttpService } from './services/apiService/apiHttp.service';
import { ApiMainService } from './services/apiService/apiMain.service';
import { LocalstorageService } from './services/utilService/localstorage.service';
import { ExportToExcelService } from './services/utilService/exportToExcel.service';
import { SessionCacheService } from './services/utilService/sessioncache.service';
import { DataformatterService } from './services/utilService/dataformatter.service';
import { PrintService } from './services/utilService/print.service';
import { MsgManager } from './services/msgManager/msgManager.service';
import { NavigationService } from './services/utilService/navigation.service';
import { CustomerRelatedService } from './services/utilService/customerRelated.service';
import { BackgroundApiCallService } from './services/utilService/backgroundApiCall.service';
import { ScreenManagerService } from './services/utilService/screen-manager.service';
import { ExportToPdfService } from './services/utilService/exportToPdf.service';
import { FormatDateService } from './services/utilService/formatDate.service';
import { ChildWindowService } from './services/utilService/childWindow.service';
import { SendDataToComponent } from './services/utilService/sendDataToComponent';

Raven.config('https://f07d27ac28084fe49eba71f8d9a293cb@sentry.io/285896', {
  environment: environment.envName
}).install();

export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    Raven.captureException(err.originalError || err);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    ArrayfilterPipe,
    ArraysortPipe,
    ExtractStringPipe,
    QuickPaymentComponent,
    StatusDateRangePipe,
    MainloaderComponent,
    BodyeventsDirective,
    ToasterComponent,
    ErrorPageComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DateInputsModule,
    DropDownsModule,
    DialogModule,
    ButtonsModule,
    LayoutModule,
  ],
  providers: [
    ApiConfigService,
    ApiHttpService,
    ApiMainService,
    LocalstorageService,
    SessionCacheService,
    LoaderstatusService,
    ExportToExcelService,
    DataformatterService,
    PrintService,
    ToasterService,
    MsgManager,
    NavigationService,
    CustomerRelatedService,
    BackgroundApiCallService,
    ScreenManagerService,
    ExportToPdfService,
    SendDataToComponent,
    FormatDateService,
    ChildWindowService,
    { provide: ErrorHandler, useFactory: ravenFactory },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function ravenFactory() {
  if (environment.loggingSentryEnabled) {
    return new RavenErrorHandler();
  }
  return new ErrorHandler();
}
