export const GOOGLE_ANALYTICS_CATEGORY  = {
    QuickPayments : 'Quick Payments'
};

export const GOOGLE_ANALYTICS_ACTION = {
    Download : 'Download',
    Pay : 'Pay',
    Verification : 'Verification'
};

export const GOOGLE_ANALYTICS_LABEL = {
    qp1 : 'I\'m not a robot',
    qp2 : 'Proceed',
    qp3 : 'Login',
    qp4 : 'Make partial payment',
    qp5 : 'Email', // removed
    qp6 : 'Download/Print',
    qp7 : 'Make Another Payment',
    qp8 : 'Pay Now'
};
