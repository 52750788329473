import { Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'extractString',
    pure: false
})
export class ExtractStringPipe implements PipeTransform {
    transform(input: any, index: number): any {
        let result;
        if ( index < 0 ) { // for last index of string
             result = input.substring(input.length + index);
        } else { // for starting of string
            result = input.substring(0, index);
        }
        return result;
    }
}
