export const COMMON_STRING = {
        COMPANY1: 'CSC',
        COMPANY2: 'TCC',
        INTERNAL_USER: 'INTERNAL',
        EXTERNAL_USER: 'EXTERNAL',
        DATEPICKER_FORMAT: 'mm/dd/yyyy',
        ALL_STATUS: 'ALL'
};

export const HEADER_COMPONENT_STRING = {
};


