import { Component, OnInit } from '@angular/core';
import { ToasterService } from './toaster.service';
import { pathList } from '../../constants/constants';
import * as Service from '../../services/service.pathconfig';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})
export class ToasterComponent implements OnInit {
  showToaster: boolean;
  hideCloseToaster;
  imagePath = pathList.imageUrl;
  toasterObj;
  constructor(
    private toasterService: ToasterService,
  ) {

  }

  ngOnInit() {
    this.showToaster = false;
    this.toasterService.toasterServiceSubject.subscribe((toasterObj) => {
      if (toasterObj) {
        this.showToasterTemplate(toasterObj);
      }
    });
  }
  showToasterTemplate(toasterObj) {
    // set classes
    // set Message
    this.showToaster = true;
    this.toasterObj = toasterObj;
    this.hideCloseToaster = toasterObj.flag ? toasterObj.flag : false;
    if (this.hideCloseToaster) {
      setTimeout(() => {
        this.showToaster = false;
      }, 3000);
    }
  }
  closeToaster() {
    this.showToaster = false;
    if (this.toasterObj.callback) {
      try {
        this.toasterObj.callback();
      } catch (e) {
      }
    }
  }

}
