import { Injectable } from '@angular/core';

@Injectable()
export class ChildWindowService {

    openChildWindowWithMessage() {
        const openWindow = window.open('about:blank');
        openWindow.document.write('<h1 id="childMessage">Your document is being loaded...</h1>');
        return openWindow;
    }

    hideMessageInChildWindow(openWindow) {
        openWindow.document.getElementById('childMessage').innerHTML = '';
    }

    showErrorMessageInChildWindow(openWindow) {
        openWindow.document.getElementById('childMessage').innerHTML = '';
        openWindow.document.write('<h1 id="childMessage">Failed to load document!!!</h1>');
    }
}
