import { Injectable } from '@angular/core';

@Injectable()
export class FormatDateService {
  constructor() {}

  stringToDate(inputdateString: string, seprator?: string): Date {
    if (inputdateString) {
      const datestring = '';
      const datestringArr = [];
      const defaultSeprator = seprator ? seprator : '-';
      const yearformatArray = inputdateString.split(defaultSeprator);
      if (yearformatArray[2].length === 4) {
        const year = parseInt(yearformatArray[2], 10);
        const day = parseInt(yearformatArray[1], 10);
        const month = parseInt(yearformatArray[0], 10) - 1;
        const finalDate = new Date(year, month, day, 0o0, 0o0, 0o0, 0o0);
        return finalDate;
      } else if (yearformatArray[0].length === 4) {
        const year = parseInt(yearformatArray[0], 10);
        const day = parseInt(yearformatArray[2], 10);
        const month = parseInt(yearformatArray[1], 10) - 1;
        const finalDate = new Date(year, month, day, 0o0, 0o0, 0o0, 0o0);
        return finalDate;
      }
    } else {
      return null;
    }
  }

  dateToString(inputdate: object, seprator?: string, format?: string): string {
    if (inputdate) {
      let datestring = '';
      const datestringArr = [];
      const defaultSeprator = seprator ? seprator : '-';
      const defaultFormat = format ? format : 'yyyy-mm-dd';
      const yearformatArray = defaultFormat.split(defaultSeprator);
      const dateObj = { yyyy: '', mm: '', dd: '' };
      if (
        inputdate.constructor.name === 'Date' ||
        inputdate.constructor.name === 'String'
      ) {
        const tempDate = new Date(inputdate.toString());
        dateObj.mm = (tempDate.getMonth() + 1).toString();
        dateObj.mm = parseFloat(dateObj.mm) > 9 ? dateObj.mm : '0' + dateObj.mm;
        dateObj.yyyy = tempDate.getFullYear().toString();
        dateObj.dd = tempDate.getDate().toString();
        dateObj.dd = parseFloat(dateObj.dd) > 9 ? dateObj.dd : '0' + dateObj.dd;
      } else {
        dateObj.mm = inputdate['month'].toString();
        dateObj.yyyy = inputdate['year'].toString();
        dateObj.dd = inputdate['day'].toString();
      }
      yearformatArray.forEach(formatstring => {
        datestringArr.push(dateObj[formatstring]);
      });
      datestring = datestringArr.join(defaultSeprator);
      return datestring;
    } else {
      return null;
    }
  }

  stringToStringDate(date) {
    if (date) {
      const temp = this.stringToDate(date);
      return this.dateToString(temp, '/', 'mm/dd/yyyy');
    } else {
      return null;
    }
  }
}
