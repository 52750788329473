export class CollectorUserInfo {
    accountNumber: string;
    accountName: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    emailAddress: string;
    userType: string;
    businessName: string;
    customerId: string;
    collectorName: string;
    currencySymbol: string;
}
