import { Injectable } from '@angular/core';
import { utils, write, WorkBook } from 'xlsx';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';


@Injectable()
export class ExportToExcelService {

  constructor() {
  }

  downloadExcel(data: any, filname: string): void {
    const ws_name = filname;
    const wb: WorkBook = { SheetNames: [], Sheets: {} };
    const ws: any = utils.json_to_sheet(data);
    const wscols = [];
    for (const i of  Object.keys(data[0])) {
      wscols.push({ wch: 20 });
    }
    ws['!cols'] = wscols;
    // this.wrapAndCenterCell(ws.B2);
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    const wbout = write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });
    saveAs(new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }), filname + '.xlsx');
  }

  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      /* eslint-disable-next-line */
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  }
}
