import { Injectable } from '@angular/core';
import { ToasterService } from '../../components/toaster/toaster.service';

@Injectable()
export class MsgManager {
  msgArray = {
    100: 'No Data found',
    101: 'An error occured',
    102: 'This is a warning',
    103: 'Set Context before Search',
    104: 'Error while trying to login. Please try again later',
    105: 'Please select at least one checkbox to proceed',
    106: 'Please fill all the mandatory details',
    107: 'Error in API fetching',
    108: 'Select a Row to update Matter Number',
    109: 'Please Apply New Matter Number before Saving it',
    110: 'Matter Number(s) Successfully Updated',
    111: 'Some Error Occurred. Please try again later',
    112: 'Please select only one checkbox to proceed',
    113: 'Email sent successfully',
    114: 'Email not sent, please try again',
    115: 'Both Customer ID and Invoice Number can not be blank',
    116: 'Selected Invoices not eligible for adjustments',
    117: 'Sorry.  You may only process a maximum of 10 invoices at a time for this action',
    118: 'Invoices with multiple currency values cannot be paid together',
    119: 'Your request is being processed and may take up to 10 minutes. Please search the Invoice again to see the new balance',
    120: 'Customer Name field cannot be blank',
    121: 'Please enter a valid amount!',
    122: 'Please enter a valid email address',
    123: 'It could take up to 15 minutes for your invoice to reflect as paid'
  };

  constructor(private toasterServiceObj: ToasterService) { }

  successMsg(number) {
    typeof number !== 'number' ? this.toasterServiceObj.show(number, 'SUCCESS')
      : this.toasterServiceObj.show(this.msgArray[number], 'SUCCESS');
  }
  errorMsg(number) {
    typeof number !== 'number' ? this.toasterServiceObj.show(number, 'ERROR')
      : this.toasterServiceObj.show(this.msgArray[number], 'ERROR');
  }
  warningMsg(number) {
    typeof number !== 'number' ? this.toasterServiceObj.show(number, 'WARNING')
      : this.toasterServiceObj.show(this.msgArray[number], 'WARNING');
  }
  infoMsg(number, flag?, callback?: () => void) {
    typeof number !== 'number' ? this.toasterServiceObj.show(number, 'INFO', flag, callback)
      : this.toasterServiceObj.show(this.msgArray[number], 'INFO', flag, callback);
  }
}
